import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You can configure some or all of your animation to repeat multiple times.`}</p>
    <h2 {...{
      "id": "looping-the-whole-track"
    }}>{`Looping the Whole Track`}</h2>
    <p>{`To make the whole animation loop, set `}<inlineCode parentName="p">{`endBehavior="loop"`}</inlineCode>{` in your `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "boomerang"
    }}>{`Boomerang`}</h2>
    <p>{`Boomerang is a special kind of loop: after your animation or region finishes, it will play backwards to its starting position before looping again.`}</p>
    <p>{`To boomerang a whole animation, set `}<inlineCode parentName="p">{`endBehavior="boomerang"`}</inlineCode>{` in your `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "looping-regions"
    }}>{`Looping Regions`}</h2>
    <p>{`You can configure individual regions to loop or boomerang as well. Loop configuration is set via each track region's `}<inlineCode parentName="p">{`loop`}</inlineCode>{` property.`}</p>
    <p>{`To make a region loop a certain number of times, set `}<inlineCode parentName="p">{`loop.count`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  duration: 1000,
  state: { x: { to: 5 } },
  loop: {
    count: 2 // Will play three times: once like normal, then loop twice
  }
}
`}</code></pre>
    <p>{`To make a region loop until a certain time, set `}<inlineCode parentName="p">{`loop.until`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  duration: 1000,
  state: { x: { to: 5 } },
  loop: {
    until: 2000 // Will loop until 2000 (for 1 second total)
  }
}
`}</code></pre>
    <p>{`To make a region loop for a certain amount of time, set `}<inlineCode parentName="p">{`loop.duration`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  duration: 1000,
  state: { x: { to: 5 } },
  loop: {
    duration: 2000 // Will loop for 2 seconds (playing 2 additional times)
  }
}
`}</code></pre>
    <p>{`To make a region boomerang, set `}<inlineCode parentName="p">{`loop.boomerang`}</inlineCode>{`. This property can be used in combination with any other loop config.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  duration: 1000,
  state: { x: { to: 5 } },
  loop: {
    boomerang: true,
    count: 2
  }
}
`}</code></pre>
    <h2 {...{
      "id": "passive-loops"
    }}>{`Passive Loops`}</h2>
    <p>{`You may also set a track region's loop to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, rather than an object. If a track region's loop is `}<inlineCode parentName="p">{`true`}</inlineCode>{` or `}<inlineCode parentName="p">{`{ boomerang: true }`}</inlineCode>{`, then it is called a `}<strong parentName="p">{`passive loop`}</strong>{`.`}</p>
    <p>{`Passive loops do not have a defined end time. Rather, they run until something else stops them...or forever, if nothing does.`}</p>
    <p>{`If there are any passive loops in a `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` of which `}<inlineCode parentName="p">{`endBehavior="continue"`}</inlineCode>{`, those passive loops will repeat indefinitely.`}</p>
    <h2 {...{
      "id": "further-reading"
    }}>{`Further Reading`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/types#loopconfig"
        }}>{`LoopConfig API`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/types#trackregion"
        }}>{`TrackRegion API`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      